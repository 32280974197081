/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';

const Layout = ({ className, children, fullpageApi, showFooter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          name
          social {
            email
          }
        }
      }
    }
  `);

  return (
    <section className={`font-body ${className}`}>
      <Header fullpageApi={fullpageApi} title={data.site.siteMetadata.name} />
      <div>
        <main>{children}</main>
      </div>
      {showFooter && <Footer data={data} />}
    </section>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  fullpageApi: PropTypes.any.isRequired,
  showMenuIcon: PropTypes.bool,
  showFooter: PropTypes.bool,
};

Layout.defaultProps = {
  className: '',
  showMenuIcon: false,
  showFooter: false,
};

export default Layout;
