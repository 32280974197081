import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Page Not Found" />
    <div className="py-12">
      <h1 className="text-center text-2xl">404</h1>
      <p className="text-center">Page Not Found</p>
    </div>
  </Layout>
);

export default NotFoundPage;
