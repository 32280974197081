import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ data }) => {
  return (
    <footer className="bg-gray-900 py-6">
      <div className="flex flex-col container mx-auto md:flex-row md:items-center md:justify-between">
        <div className="flex align-center justify-center md:w-auto">
          <a
            href={data.site.siteMetadata.social.email}
            target="_blank"
            rel="noreferrer noopener"
            className="rounded-full bg-white text-gray-900 w-10 h-10 m-3 flex items-center justify-center"
          >
            Email
          </a>
        </div>
        <div className="text-center text-gray-300 text-sm md:text-base md:flex md:grow md:pl-6 md:justify-end">
          <div className="md:mr-3">
            © {new Date().getFullYear()} | Powered by{' '}
            <a
              href="https://xeromedia.com"
              target="_blank"
              rel="noreferrer noopener"
              className="text-white underline cursor-pointer"
            >
              XeRo Media
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        social: PropTypes.shape({
          email: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default Footer;
